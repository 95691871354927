import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
declare var $:any 
@Component({
  selector: 'app-editorailboard',
  templateUrl: './editorailboard.component.html',
  styleUrls: ['./editorailboard.component.css']
})
export class EditorailboardComponent implements OnInit {

  constructor(private http:HttpClient) { }

  editors : any = []

  ngOnInit(): void {

    this.http.get('assets/allmembers/editorBoard.json').subscribe( data => {
      this.editors = data
    })


    $(document).ready(function(){
      $('.materialboxed').materialbox();
    });
          
  }

}
