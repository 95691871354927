import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-leftpanel',
  templateUrl: './leftpanel.component.html',
  styleUrls: ['./leftpanel.component.css']
})
export class LeftpanelComponent implements OnInit {
 
  headerItems: Object;

  constructor(private http:HttpClient) { }

  ngOnInit(): void {
    this.http.get('assets/header/header.json').subscribe( data => {
      this.headerItems = data 
    })

  }

}
