<div class="seciton bg center" style="padding: 30px;">
    <p class="center white-text" style="font-size: xx-large"> PEER REVIEW COMMITTEE

    </p>
</div>

<div class="section grey lighten-4" >
   
    <div class="container" style="position:relative;top:-50px;">
        
      <div class="card" *ngFor="let item of peerreviewers">
        <div class="card-image waves-effect waves-block waves-light">
          <!-- <img class="activator" src="images/office.jpg"> -->
        </div>
        <div class="card-content">
          <p class="pink-text" style="font-size: large;">{{item.name}}</p>
          <p *ngIf="item.designation != ''">{{item.designation}},</p>
          <p *ngIf="item.department != ''">{{item.department}},</p>
          <p *ngIf="item.institute != ''">{{item.institute}},</p>
          <p *ngIf="item.add != ''">{{item.add}}</p>
        </div>
        <div class="card-reveal">
          <span class="card-title grey-text text-darken-4">Card Title<i class="material-icons right">close</i></span>
          <p>Here is some more information about this product that is only revealed once clicked on.</p>
        </div>
      </div>

    </div> 
</div>