import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
declare var $:any

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(private http:HttpClient) { }

  headerItems : any = []
 
  ngOnInit(): void {
 
    this.http.get('assets/header/header.json').subscribe( data => {
      this.headerItems = data 
    })

    $(document).ready(function(){
      $('.sidenav').sidenav(
        {
          preventScrolling:false
         }
      );
      $(".dropdown-trigger").dropdown(
        {
          hover:true,
          constrainWidth:false,
          coverTrigger:false
        }
      );

    });

    var $header = $(".header"),
            $clone = $header.before($header.clone().addClass("fixed"));
        
        $(window).on("scroll", function() {
            var fromTop = $(window).scrollTop();
            $("body").toggleClass("on-scroll", (fromTop > 200));
      });

  }


  
}
