import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vidyavaridhi',
  templateUrl: './vidyavaridhi.component.html',
  styleUrls: ['./vidyavaridhi.component.css']
})
export class VidyavaridhiComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
