  <div class="section indigo lighten-5">
  <div class="container2">
      <div class="row" >
  <div class="col l6 indigo lighten-5" >
     <div class="section teal darken-1 white-text center"> 
         <span class="center" style="padding: 20px; font-size: large;"> <b> THRUST AREA OF RESEARCH IN EDUCATION  </b> </span>
      </div>
  
  <!-- <div class="section teal darken-1 white-text">
              <h5 class="center"> <b> THRUST AREA OF RESEARCH IN EDUCATION </b> </h5>
              -->
  <!-- </div> -->
  <p style="text-align: justify;" class="indigo-text"> 
      The prime aims to provide a forum for all educators, educational policy-makers and planners to exchange invaluable ideas and resources encompasses variety of topics in Education including but are not limited to -
  </p>
  <!-- <div class="section "> -->
      <!-- <div class="container22"> -->
              <!-- <div class="row">
                  <div class="col s12 l12"> -->
                      <!-- <ul class="collection  black-text">
                          <li class="collection-item" *ngFor="let item of list1"> 
                              {{item.topic2}}
                          </li>                                             
                      </ul> -->
                      <div class="row">
                          <div class="col s12 l4" *ngFor="let item of list1">
                              <div class="card-panel topic z-depth-2 hoverable" >
                                  <span>{{item.topic}}</span>
                              </div>
                          </div>
                      </div>
                  <!-- </div>
              </div> -->
  </div>
  <!-- col ends here -->
  
  <div class="col l6 indigo lighten-5">
      <div class="section cyan darken-1 white-text center"> 
          <span class="center" style="padding: 20px; font-size: large;"> <b> THRUST AREA OF RESEARCH IN SANSKRIT  </b> </span>
       </div>
  
  
  <p style="text-align:center;" class="indigo-text"> 
      This Journal considers review and research articles related to  -
  </p>
  
              <div class="row">
                  <div class="col s12 l6">
                      <ul class="collection black-text">
                          <li class="collection-item z-depth-1 indigo-text">  Vedas, Vedangas, Upanishads and Agamic Systems  </li>
                          <li class="collection-item z-depth-1 indigo-text">  Computation Linguistics</li>
                          <li class="collection-item z-depth-1 indigo-text">  Contemporary Sanskrit Writings</li>
                          <li class="collection-item z-depth-1 indigo-text">  Culture and Tradition</li>
                          <li class="collection-item z-depth-1 indigo-text">  Darsana, Puranas, Grammar, Sahitya</li>
                          <li class="collection-item z-depth-1 indigo-text">  Indian Aesthetics and Poetics</li>
                          <li class="collection-item z-depth-1 indigo-text">  Pali and Prakrit Studies</li>
                          <li class="collection-item z-depth-1 indigo-text">  Pauranic Literature</li>
                          <li class="collection-item z-depth-1 indigo-text">  Religion, History</li>
                          <li class="collection-item z-depth-1 indigo-text">  Sanskrit Studies</li>
                          <li class="collection-item z-depth-1 indigo-text">  Sanskrit Grammar and Linguistics</li>
                          <li class="collection-item z-depth-1 indigo-text">   Manuscriptology and Palaeography</li>
  
                          
                      </ul>
                  </div>
                  <div class="col s12 l6">
                      <ul class="collection black-text">
                          <li class="collection-item z-depth-1 indigo-text">  Sanskrit Lexicography</li>
                          <li class="collection-item z-depth-1 indigo-text">  Sanskrit Literature</li>
                          <li class="collection-item z-depth-1 indigo-text">   Scientific Traditions in Sanskrit</li>
                          <li class="collection-item z-depth-1 indigo-text">   Veda and Vedic Studies</li>
                          <li class="collection-item z-depth-1 indigo-text">   Buddhist literature</li>
                          <li class="collection-item z-depth-1 indigo-text">   Indian and Western Logical Systems</li>
                          <li class="collection-item z-depth-1 indigo-text">   Indian Discourse Analysis</li>
                          <li class="collection-item z-depth-1 indigo-text">   Indian Philosophy</li>
                          <li class="collection-item z-depth-1 indigo-text">   Indian Socio-Political Thought</li>
                          <li class="collection-item z-depth-1 indigo-text">   Indological studies</li>
                          <li class="collection-item z-depth-1 indigo-text">   Jainism and Buddhism</li>
                          <li class="collection-item z-depth-1 indigo-text">   Hindu astrology (Jyoti?sastra)</li>
                          <li class="collection-item z-depth-1 indigo-text">   Research Methodology</li>
  
                          <!-- <li class="collection-item">   &nbsp;</li> -->
  
                      </ul>
                  </div>
                  
              </div>
                 
          <!-- </div> -->
      <!-- </div> -->
      <!-- </div> -->
  <!-- </div> -->
  
  
  </div>
  </div>
  </div>
  </div>