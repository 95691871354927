import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { DownloadformsComponent } from './downloadforms/downloadforms.component';
import { RulesregulationsComponent } from './rulesregulations/rulesregulations.component';
import { OnlineappsComponent } from './onlineapps/onlineapps.component';
import { ConvocationComponent } from './convocation/convocation.component';
import { RevaluationComponent } from './revaluation/revaluation.component';
import { ChangenameComponent } from './changename/changename.component';
import { VidyavaridhiComponent } from './vidyavaridhi/vidyavaridhi.component';
import { AboutjournalComponent } from './aboutjournal/aboutjournal.component';
import { InspirationComponent } from './inspiration/inspiration.component';
import { EditorailboardComponent } from './editorailboard/editorailboard.component';
import { LifemembersComponent } from './lifemembers/lifemembers.component';
import { JournalcontentComponent } from './journalcontent/journalcontent.component';
import { GuidelinesComponent } from './guidelines/guidelines.component';
import { DeclarationComponent } from './declaration/declaration.component';
import { ImportantlinksComponent } from './importantlinks/importantlinks.component';
import { PeerpolicyComponent } from './peerpolicy/peerpolicy.component';
import { AdvisoryboardComponent } from './advisoryboard/advisoryboard.component';
import { PeerreviewComponent } from './peerreview/peerreview.component';
import { ContactusComponent } from './contactus/contactus.component';
import { GallaryComponent } from './gallary/gallary.component';
import { SubscriptionformComponent } from './subscriptionform/subscriptionform.component';


const routes: Routes = [
  {path:"", redirectTo:"home",pathMatch:"full"},
  {path:"home", component:HomeComponent},
  {path:'downlaodforms', component:DownloadformsComponent},
  {path:'rulesregulations', component: RulesregulationsComponent},
  {path:'onlineapps', component: OnlineappsComponent},
  {path:"convocation", component:ConvocationComponent},
  {path:'revaluation', component:RevaluationComponent},
  {path:'changename', component:ChangenameComponent},
  {path:'vidyavaridhi',component:VidyavaridhiComponent },
  {path:"about", component:AboutjournalComponent},
  {path:"inspiration", component:InspirationComponent},
  {path:"editorial", component:EditorailboardComponent},
  {path:"members", component: LifemembersComponent},
  {path:"content", component:JournalcontentComponent},
  {path:"guidelines", component:GuidelinesComponent},
  {path:"declaration", component:DeclarationComponent},
  {path:"important",component:ImportantlinksComponent},
  {path:"peerpolicy", component: PeerpolicyComponent },
  {path:"advisory", component:AdvisoryboardComponent},
  {path:"peerreview", component:PeerreviewComponent},
  {path:"contactus", component:ContactusComponent},
  {path:"gallary", component:GallaryComponent},
  {path:"subscription", component:SubscriptionformComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash:true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
