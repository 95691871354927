
    <!-- <app-slider></app-slider> -->
    <!-- <div class="row">
        <div class="col s3 l3">
            <app-leftpanel></app-leftpanel>
        </div>
        <div class="col s9 l9"> -->
            <!-- <app-noticeboard></app-noticeboard> -->
        <!-- </div>
    </div>
    
    <br> -->
    <app-slider></app-slider>

<div class="section grey22 lighten-5">
    <div class="row">
        <div class="col s12 l8">
            <div class="container2"><h5 class="purple-text"> <b> About Journal</b></h5><hr></div>
            <app-aboutjournal></app-aboutjournal>
        </div>
        <div class="col s12 l4 hide-on-med-and-down grey lighten-5"  style="overflow: auto; height: 700px;">
            <!-- <div class="col s12 l8"> -->
                <b><h5 class="purple-text"> <i class="material-icons left">book</i> Journal Content</h5></b>
            <app-journalcontent></app-journalcontent>
        </div>
    </div>
</div>
<app-topics></app-topics>
<app-importantlinks></app-importantlinks>