import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-declaration',
  templateUrl: './declaration.component.html',
  styleUrls: ['./declaration.component.css']
})
export class DeclarationComponent implements OnInit {

  constructor(private http:HttpClient) { }

  declaration: any = []
  ngOnInit(): void {
    this.http.get('assets/declaration/declaration.json').subscribe( data => {
      this.declaration = data
    })

  }

}
