<div class="seciton bg center" style="padding: 50px;">
    <span class="center white-text" style="font-size: xx-large"> Life Members </span>
</div>

<div class="section grey lighten-4">
    
    <div class="container" style="position:relative;top:-50px;">
      <div class="card-panel22">
        <!-- <table>
            <tbody *ngFor="let mem of memberList; let i=index">
            <tr style="margin-bottom: 20spanx;">
                <td style="vertical-align: tospan;"> {{i+1}}. </td>
                <td> 
                    <span class="red-text" style="font-size: large;">{{mem.name }}</span><br>
                    <span> {{mem.designation}}</span><br>
                    <span> {{mem.add}}</span>
                </td>
            </tr>
            </tbody>
        </table>
      </div> -->

        <!-- <ul class="collection"> -->
            <!-- <li *ngFor="let mem of memberList; let i=index" class="collection-item"> -->
                <div class="card-panel" *ngFor="let mem of memberList; let i=index">
                <div class="row">
                    <div class="col s3 l1 ">
                        <img src="{{mem.image}}" height="50" width="50">
                    </div>
                    <div class="col s9 l11">
                        <p class="pink-text" style="font-size: large;"> {{mem.name }}</p>
                        <p class=""> {{mem.designation}}</p>
                        <p *ngIf="mem.department != ''">{{mem.department}}</p>
                        <p class=""> {{mem.add}}</p>
                    </div>
                </div>
            </div>
            <!-- </li>
        </ul> -->
    </div>
    </div>

</div>