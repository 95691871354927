<div class="seciton bg center" style="padding: 50px;">
    <span class="center white-text" style="font-size: xx-large"> PEER REVIEW POLICY </span>
</div>


<div class="section grey lighten-5">
<div class="container" >
    
<div class="card-panel" style="position:relative;top:-50px;">
    <h5 class="center pink-text">INFORMATION</h5>
       <p>
            This is informed to all the writers and readers that "Shodhasamiksha" is being declared as a "Peer Reviewed Journal" by constituating a Peer Review Committee with eminent sanskrit scholars and educationists to improve the quality and maaintain the plagiarism in the field of Research by reviewing the articles before publish. The reviewing systme is double-blind.
            
       </p>
       <h5 class="black-text"> PEER REVIEW PROCESS</h5>
        <h6>(1) Submission of Paper</h6>
        
        <p>The corresponding or submitting author will submit the paper to the journal. This is usually via ESSENCE online submission system or through postal service or through email.</p>
        
       <h6> (2) Editorial Office Assessment</h6>
        
       <p>
            The journal will go through the paper’s composition and arrangement against the Author Guidelines of Journal to make sure it includes the required sections and stylizations. The quality of the paper will not be assessed at this point.
            
       </p>
       <h6> (3) Appraisal by the Editor-in-Chief</h6>
        
        <p>
            The EIC will check that the paper is appropriate for the journal and is sufficiently original and interesting. If not, the paper may be rejected without being reviewed any further.
            
        </p>
       <h6> 4. EIC/EE Assigns an Associate Editor (AE)</h6>
        
      <p>  Associate Editors will handle the peer review system. If they do, they will be assigned at this stage.</p>
        
        <h6>
            (5) Invitation to Reviewers
            
        </h6>
        <p>Editor-in-chief or the editorial board will be will send invitations to individuals who are appropriate reviewers for a particular subject. As responses are received, further invitations will be issued, if necessary, until the required number of acceptances is obtained.</p>
        
      <h6>  (6) Response to Invitations</h6>
      <p>
            
            Potential reviewers should consider the invitation against their own expertise, conflicts of interest and availability. They then may accept or decline. If possible, when declining, they might also suggest alternative reviewers.
      </p>
        
     <h6>   (7) Conducing of review</h6>
        
       <p> The reviewer will set time aside to read the paper several times. The first read will be used to form an initial impression of the work. If major problems are found at this stage, the reviewer may feel comfortable for rejecting the paper without further work. Otherwise they will read the paper several more times, taking notes so as to build a detailed point-by-point review. The review will be then submitted to the journal, with a recommendation to accept or reject it – or else with a request for revision (usually flagged as either major or minor) before it is reconsidered.</p>
        
      <h6>  (8) Journal Evaluates the Reviews</h6>
        
      <p>  The handling editor will consider all the returned reviews before making an overall decision. If the reviews differ widely, the editor may invite an additional reviewer so as to get an extra opinion before making a decision.</p>
        
       <h6> (9) Communication of the Dicission</h6>
        
       <p> The editor will send a decision email to the author including any relevant reviewer comments. Whether the comments are anonymous or not will depend on the type of peer review that the journal operates.</p>
        
     <h6>   (10) Next Steps</h6>
        
       <p> If accepted, the paper will be sent to production. If the article is rejected or sent back for either major or minor revision, the handling editor should include constructive comments from the reviewers to help the author to improve the article At this point, reviewers should also be sent an email or letter letting them know the outcome of their review. If the paper was sent back for revision, the reviewers should expect to receive a new version, unless they have opted out of further participation. However, where only minor changes were requested this follow-up review might be done by the handling editor.</p>
        
</div>
</div>
</div>