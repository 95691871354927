import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-peerreview',
  templateUrl: './peerreview.component.html',
  styleUrls: ['./peerreview.component.css']
})
export class PeerreviewComponent implements OnInit {

  constructor(private http:HttpClient) { }

  peerreviewers:any = []
  ngOnInit(): void {
    this.http.get('assets/peerreviewers/peerreviewers.json').subscribe( data => {
        this.peerreviewers = data
    })
  }

}
