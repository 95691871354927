import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-importantlinks',
  templateUrl: './importantlinks.component.html',
  styleUrls: ['./importantlinks.component.css']
})
export class ImportantlinksComponent implements OnInit {

  constructor(private http:HttpClient) { }

  importantLinks : any = []
  ngOnInit(): void {
      this.http.get('assets/importantlinks/important.json').subscribe( data => {
        this.importantLinks = data
      })

      
  }

}
