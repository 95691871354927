<div class="section grey lighten-2 center">

    <!-- <div class="row">
        <div class="col" *ngFor="let item of importantLinks"> -->
            <a href="{{item.href}}" target="_blank" *ngFor="let item of importantLinks"> 
                <img src="{{item.link}}" width="110" height="80" class="hoverable"> &nbsp;
            </a>
        <!-- </div>
    </div> -->
</div>
