<div class="seciton bg center" style="padding: 50px;">
    <span class="center white-text" style="font-size: xx-large"> GUIDELINES TO AUTHORS </span>
</div>

<div class="section">

    <div class="container" >

        <p> Submission of manuscript should be the original work of the author(s).  Author(s) is / are required transferring the copyright of the manuscript to the Publisher for publishing in the journal.  The publisher holds copyright of the manuscript unless otherwise not communicated.  It further implies that the manuscript submitted has neither published anywhere nor been kept under consideration for publication.  The contributors must provides their affiliations and complete mailing addresses on their papers.</p>


        <p>Submitted manuscripts in English/Sanskrit should be up to about 3000 words including abstract, tables and references.  An abstract of around 100 words, key words a brief description about themselves along with areas of their specializations may be submitted along with the manuscript.</p>
        
        
       <p>
            The English manuscript should be submitted as MS word file in the times new roman (12 size) with 1.5 spacing.  The Sanskrit manuscript should be submitted as PageMaker file in AkrutiDevPriya (16 size) with 1.5 spacing.  Two copies of the manuscript, typed in one side along with soft copy (email/CD) should be addressed to </p>
            <!-- <p id="add"> -->
                <address>
            Dr. Sanat Kumar Rath, <br>
            Editor, Shodha Samiksha, <br>
            Shrishti Appartments, <br>
            Simanta Pally, Santiniketan, <br>
            West Bengal – 731 235.  <br>
            Email –<a href="#!"> shodhasamiksha74@gmail.com </a><br>
            &nbsp; &nbsp; &nbsp; &nbsp; or <br>
            Dr. Ramakanta Mohalik, <br>
            Regional Institute of Education, <br>
            Bhubaneswar, Odisha.  <br>
            E-mail <a href="#!">ranajabtanigakuj@rediffmail.com</a>
        </address>
            <!-- </p> -->
       
        
       <p> The journal also reviews educational publications other than textbooks.  Authors/publishers may send a copy of their latest publication for book review.  The authors should provide the list of references at the end of the paper in sequence as per the guidelines and the examples suggested.</p>
            
   <ol>
       
      <li> Pollard, A (2002).  Reflective Teaching, continuum, The tower Building, London.</li>
    
          <li> Mohalik, R.K (2010).  In-service teacher education, Mahamaya Publishing House, New Delhi.</li>
           
        <li>   Behera, L (2005).  Evaluation of elementary level teacher education syllabus of Odisha, Indian Education Review41(2), pp-117.</li>
           
           <li>
               NCERT.  National curriculum Framework-2005, National Council of Educational research and training, New Delhi.
               
           </li>
           <li>Singh, V et.al (2009).  Constructivism and teacher preparation at elementary state.  In constructivism and quality elementary education.  Proceedings of national seminar, 2009, 17-19 march, RIE, Bhubaneswar, pp.110-116.</li>
           
          <li> Dalei, L (2010).  Effectiveness of cooperative teaching strategy on achievement and a social competency.  Unpublished Ph.D Thesis, Utkal University, Bhubaneswar.</li> 
        </ol>

    

</div>