import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { SliderComponent } from './slider/slider.component';
import { NotificationComponent } from './notification/notification.component';
import { RightpanelComponent } from './rightpanel/rightpanel.component';
import { LeftpanelComponent } from './leftpanel/leftpanel.component';
import { MiddlepanelComponent } from './middlepanel/middlepanel.component';
import { LatestnewsComponent } from './latestnews/latestnews.component';
import { DownloadformsComponent } from './downloadforms/downloadforms.component';
import { HttpClientModule } from '@angular/common/http';
import { NoticeboardComponent } from './noticeboard/noticeboard.component';
import { RulesregulationsComponent } from './rulesregulations/rulesregulations.component';
// import { PdfViewerModule } from 'ng2-pdf-viewer';
import { OnlineappsComponent } from './onlineapps/onlineapps.component';
import { ConvocationComponent } from './convocation/convocation.component';
import { RevaluationComponent } from './revaluation/revaluation.component';
import { ChangenameComponent } from './changename/changename.component';
import { VidyavaridhiComponent } from './vidyavaridhi/vidyavaridhi.component';
import { AboutjournalComponent } from './aboutjournal/aboutjournal.component';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { InspirationComponent } from './inspiration/inspiration.component';
import { EditorailboardComponent } from './editorailboard/editorailboard.component';
import { LifemembersComponent } from './lifemembers/lifemembers.component';
import { JournalcontentComponent } from './journalcontent/journalcontent.component';

// import { AngularCreatePdfModule } from 'angular-create-pdf';
import { AppRoutingModule } from './app-routing.module';
import { GuidelinesComponent } from './guidelines/guidelines.component';
import { DeclarationComponent } from './declaration/declaration.component';
import { ImportantlinksComponent } from './importantlinks/importantlinks.component';
import { TopicsComponent } from './topics/topics.component';
import { PeerpolicyComponent } from './peerpolicy/peerpolicy.component';
import { AdvisoryboardComponent } from './advisoryboard/advisoryboard.component';
import { PeerreviewComponent } from './peerreview/peerreview.component';
import { ContactusComponent } from './contactus/contactus.component';
import { GallaryComponent } from './gallary/gallary.component';
import { SubscriptionformComponent } from './subscriptionform/subscriptionform.component';
// import { RouterModule, Routes } from '@angular/router';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    SliderComponent,
    NotificationComponent,
    RightpanelComponent,
    LeftpanelComponent,
    MiddlepanelComponent,
    LatestnewsComponent,
    DownloadformsComponent,
    NoticeboardComponent,
    RulesregulationsComponent,
    OnlineappsComponent,
    ConvocationComponent,
    RevaluationComponent,
    ChangenameComponent,
    VidyavaridhiComponent,
    AboutjournalComponent,
    InspirationComponent,
    EditorailboardComponent,
    LifemembersComponent,
    JournalcontentComponent,
    GuidelinesComponent,
    DeclarationComponent,
    ImportantlinksComponent,
    TopicsComponent,
    PeerpolicyComponent,
    AdvisoryboardComponent,
    PeerreviewComponent,
    ContactusComponent,
    GallaryComponent,
    SubscriptionformComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    IvyCarouselModule,
    AppRoutingModule,
    // PdfViewerModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
