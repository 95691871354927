import { Component, OnInit } from '@angular/core';
// import Scroll from 'autoscroll.js' ;
declare var $:any
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-latestnews',
  templateUrl: './latestnews.component.html',
  styleUrls: ['./latestnews.component.css']
})
export class LatestnewsComponent implements OnInit {

  constructor(private http:HttpClient) { }

  latestNews: any = []
  ngOnInit(): void {
    this.autoscroll()

    this.http.get('assets/noticeboard/latestnews.json').subscribe( news =>
      {
        this.latestNews = news
      }
    )
  }


  autoscroll(){
    var div = $('#id-1');
    setInterval(function(){
    var pos = div.scrollTop();
    div.scrollTop(pos + 2);
    }, 50)

    
  }

}
