import { Component, OnInit, ElementRef ,ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { stringify } from 'querystring';
import { jsPDF } from "jspdf";
import Swal from 'sweetalert2'


import html2canvas from 'html2canvas';  
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-onlineapps',
  templateUrl: './onlineapps.component.html',
  styleUrls: ['./onlineapps.component.css']
})
export class OnlineappsComponent implements OnInit {

  constructor(private http:HttpClient) { }
  
  migrationForms : any = []

  ngOnInit(): void {
    this.http.get('assets/forms/migration.json').subscribe( migrationdata => {
      this.migrationForms = migrationdata
    })

  }


  // getDetails(migration:any){
    // const allValues = migration.value
    // console.log(allValues)
    // alert(stringify(allValues))
  // }

  getPdf(migration:any){
    // Default export is a4 paper, portrait, using millimeters for units
    const migFormDetails = migration.value
    var studentName = migFormDetails.studentfirstname +' '+ migFormDetails.studentsecondname
    var fatherName = migFormDetails.fatherfirstname +' '+ migFormDetails.fathersecondname
    var motherName = migFormDetails.motherfirstname +' '+ migFormDetails.mothersecondname
    var addreess = migFormDetails.address1+ ","+ migFormDetails.address2+", "+migFormDetails.state+", "+migFormDetails.addresspin
    var email = migFormDetails.email
    var contactno = migFormDetails.contactnumber
    var nadnumber = migFormDetails.nadno
    var addmissionno = migFormDetails.admissionno
    var nameofinstitution = migFormDetails.nameofinstitute
    var nameofexam = migFormDetails.nameofexam
    var monthyear = migFormDetails.monthyearexam
    var regnumber = migFormDetails.regexam
    var result = migFormDetails.resultexam
    var programename = migFormDetails.programname
    var universityname = migFormDetails.universityname
    var challenamount = migFormDetails.ddamount
    var challennumber = migFormDetails.challennumber



    if(migFormDetails.studentfirstname == ''){
      Swal.fire({
        icon:"warning",
        text:"Enter Studnet's First Name."
      })
    }else if(migFormDetails.fatherfirstname == ''){
      Swal.fire({
        icon:"warning",
        text:"Enter Father's First Name."
      })
    }else if(migFormDetails.motherfirstname == ''){
      Swal.fire({
        icon:"warning",
        text:"Enter Mother's First Name."
      })
    }
    else if(migFormDetails.address1 == ''){
      Swal.fire({
        icon:"warning",
        text:"Enter Address."
      })
    }
    else if(migFormDetails.state == ''){
      Swal.fire({
        icon:"warning",
        text:"Enter State."
      })
    }else if(migFormDetails.addresspin == ''){
      Swal.fire({
        icon:"warning",
        text:"Enter PIN of your area."
      })
    }else if(email == ''){
      Swal.fire({
        icon:"warning",
        text:"Enter EMail ID."
      })
    }else if(contactno == ''){
      Swal.fire({
        icon:"warning",
        text:"Enter Contact Number"
      })
    }else if(addmissionno == ''){
      Swal.fire({
        icon:"warning",
        text:"Enter Admission Number."
      })
    }else if(nameofinstitution == ''){
      Swal.fire({
        icon:"warning",
        text:"Enter Name of Institution."
      })
    }else if(nameofexam == ''){
      Swal.fire({
        icon:"warning",
        text:"Enter Name of Exam."
      })
    }else if(monthyear == ''){
      Swal.fire({
        icon:"warning",
        text:"Enter Month or Year of Examination."
      })
    }else if(regnumber == ''){
      Swal.fire({
        icon:"warning",
        text:"Enter Registered Number."
      })
    }else if(result == ''){
      Swal.fire({
        icon:"warning",
        text:"Enter Result."
      })
    }else if(programename == ''){
      Swal.fire({
        icon:"warning",
        text:"Enter Programe Name."
      })
    }else if(universityname == ''){
      Swal.fire({
        icon:"warning",
        text:"Enter University Name."
      })
    }else if(challenamount == ''){
      Swal.fire({
        icon:"warning",
        text:"Enter Amount of Challen."
      })
    }else if(challennumber == ''){
      Swal.fire({
        icon:"warning",
        text:"Enter Challen Number."
      })
    }
    
    
    else{
   



    // var fatherName = migFormDetails.fatherfirstname 
    const doc = new jsPDF('p', 'pt', 'a4');
    
    var margin = {top: 10, right: 20, bottom: 10, left: 20};

    // doc.addHTML(margin)
    doc.setFont("bold")
    doc.text("NATIONAL SANSKRIT UNIVERSITY: TIRUPATI",120,80)
    // doc.setFontSize(15)
    doc.text(migFormDetails.formTitle,70,110)
    doc.setFont("","normal")

    // doc.text(studentName,90,150)
    doc.setFontSize(12)
    doc.text("To",50,150)
    doc.text("THE CONTROLLER OF EXAMINATIONS",50,170)
    doc.text("NATIONAL SANSKRIT UNIVERSITY",50,190)
    
    doc.setFont("","normal")
    doc.text("1. Name : ",50,220)
    doc.setFont("","bold")
    doc.text(studentName,200,220)

    doc.setFont("","normal")
    doc.text("2. Father/Husband Name : ",50,240)
    doc.setFont("","bold")
    doc.text(fatherName,200,240)

    doc.setFont("","normal")
    doc.text("3. Mother's Name : ",50,260)
    doc.setFont("","bold")
    doc.text(motherName,200,260)

    doc.setFont("","normal")
    doc.text("4. Address : ",50,280)
    doc.setFont("","bold")
    var splitTitle = doc.splitTextToSize(addreess, 300);
    doc.text(splitTitle,200,280)

    doc.setFont("","normal")
    doc.text("5. Email-ID : ",50,320)
    doc.setFont("","bold")
    doc.text(email,200,320)

    doc.setFont("","normal")
    doc.text("6. Mobile Number : ",50,340)
    doc.setFont("","bold")
    doc.text(contactno,200,340)

    doc.setFont("","normal")
    doc.text("7. Admission No : ",50,360)
    doc.setFont("","bold")
    doc.text(addmissionno,200,360)

    doc.setFont("","normal")
    doc.text("and / or NAD Number : ",300,360)
    doc.setFont("","bold")
    doc.text(nadnumber,450,360)

    doc.setFont("","normal")
    doc.text("8. Last Examination Details - ",50,380)

    doc.setFont("","normal")
    doc.text("Name of the Institution : ",70,400)
    doc.setFont("","bold")
    doc.text(nameofinstitution,260,400)

    doc.setFont("","normal")
    doc.text("Name of the examination : ",70,420)
    doc.setFont("","bold")
    doc.text(nameofexam,260,420)

    doc.setFont("","normal")
    doc.text("Month & Year of the examination : ",70,440)
    doc.setFont("","bold")
    doc.text(monthyear,260,440)

    doc.setFont("","normal")
    doc.text("Examination Registration No.: ",70,460)
    doc.setFont("","bold")
    doc.text(regnumber,260,460)

    doc.setFont("","normal")
    doc.text("Result: ",70,480)
    
    doc.setFont("","bold")
    doc.text(result,260,480)

    doc.setFont("","normal")
    var desc1 = doc.splitTextToSize("(Please attach the self-attested photocopy of the mark- sheet & Duplicate T.C. taken from the last college)", 550);
    doc.text(desc1,70,500)

    doc.setFont("","normal")
    doc.text("9. New Admission details : ",50,520)

    doc.setFont("","normal")
    doc.text("Name of the Program : ",70,540)
    doc.setFont("","bold")
    doc.text(programename,270,540)

    doc.setFont("","normal")
    doc.text("Name of the University Department : ",70,560)
    doc.setFont("","bold")
    doc.text(universityname,270,560)
    
    doc.setFont("","normal")
    var desc2 = doc.splitTextToSize("(Please attach the admission proof, such as copy of the receipt of the fee / Allotment letter)", 620);
    doc.text(desc2,70,580)


    doc.text("Date ",50,700)
    doc.text("Signature of the student ",400,700)

   
    doc.addPage()

    doc.setFont("","normal")
    var desc3 = doc.splitTextToSize("If you are sending the application by post, please attach following documents with the application.", 620);
    doc.text(desc3,70,80)

    doc.text("- Copy of the last Examination mark-sheet.",70,100)
    doc.text("- Duplicate T.C. obtained from the last college.",70,120)
    doc.text("- Copy of new admission proof.",70,140)
    doc.text("-  Self-addressed envelope without stamp. ",70,160)

    var desc4 = doc.splitTextToSize('- Demand draft of Rs.___'+ challenamount+' of any nationalized / scheduled bank Challan in the favour of, "REGISTRAR NATIONAL SANSKRIT UNIVERSITY, TIRUPATI, payable at TIRUPATI." Or challan receipt of fee paid at University',500)
    doc.text(desc4,70,180)

    var desc5 = doc.splitTextToSize('-  He / She has been student of the NSU TIRUPATI since _____ and left in the year _____. The transfer certificate is sent herewith in duplicate. On behalf of this candidate no application for Migration certificate has been made previous to this date. ',500)
    doc.text(desc5,70,240)

    doc.text("Date ",50,340)
    doc.text("Signature and Seal of the HoD of Concerned  ",320,340)
    doc.text("dept.  ",500,350)

    doc.setFont("","bold")
    doc.setFontSize(18)
    doc.text("For Office Use",250,500)
    doc.setFont("","normal")
    doc.setFontSize(12)
    var desc6 = doc.splitTextToSize(" Verified all the records submitted by the student and found correct, accordingly the Migration Certificate is prepared. MC No. is _____",500)
    doc.text(desc6,70,520)
    
    doc.setFont("","bold")
    doc.text("Clerk",120,580)
    doc.text("SO",220,580)
    doc.text("AR",320,580)
    doc.text("CE",420,580)




    doc.output('dataurlnewwindow');
    // doc.save("a4.pdf");

   
      } // end conditons
    
  }

}
