<div class="section">
        <div class="row center">
            <img class="responsive-img" src="assets/images/chancellor.jpg" width="200" height="300"> 
            <p> 
                <span class="pink-text"> Shri N. Gopalaswamy IAS </span><br>
                <span class="purple-text"> Chancellor </span>
            </p>
        </div>

        <div class="row center">
            <img  class="responsive-img" src="assets/images/vc.jpg" width="200" height="300"> 
            <p> 
                <span class="pink-text"> Prof. V. Muralidhara Sharma</span><br>
                <span class="purple-text"> Vice-Chancellor</span>
            </p>
        </div>

        <div class="row center">
            <img  class="responsive-img" src="assets/images/registrar.jpg" width="200" height="300"> 
            <p> 
                <span class="pink-text"> Prof. G.S.R. Krishnamurthy </span><br>
                <span class="purple-text"> Registrar i/c </span>
            </p>
        </div>
</div>