<div class="section grey22 lighten-5">
  <div class="container2">
      <div class="row">
          <div class="col s12 l7">
            <p>
                <span class="pink-text">"SHODHASAMIKSHA"</span> is a Peer Reviewed and Referred half yearly/bi-annual National Journal of Research in Education and Sanskrit (NJRES). It was started in January, 2011 by the able and dynamic guidance and inspiration of Former Governor of Assam and Chancellor of Rashtriya Sanskrit Vidyapeetha, Tirupati Prajnana Vachaspati (Dr.) Janaki Ballav Patnaikji ; the renowned scholars in the field of Sanskrit and Education Late Prof. Ramanuja Devanathan, Former Vice-Chancellor, J R R Sanskrit University, Jaipur, Rajasthan and Professor Divakar Kundu, Former Principal, Dept. of Education, Visva-Bharati, Shantiniketan, West Bengal.
            </p>
        
            <p>
                The main goal of this journal is to bring together researchers and practitioners from academia and industry to focus on Educational, linguistic, literal, religious, cultural, philosophical and ethical advancements, and establishing new collaborations in these areas of both Sanskrit and education. Original research papers, state-of-the-art reviews related to the Sanskrit Literature as well as the modern trends in education are invited for publication here.
            </p>
          </div>
          <div class="col s12 l5">
            <h6 class="pink-text"> 
                PLAGIARISM
            </h6>
            <p>
                The Journal does check plagiarism of submitted paper through open source software Viper. To complain, please feel free to contact us. The journal will debar the guilty authors from submitting their paper for the one year. <a href="http://www.scanmyessay.com/features.php" target="_blank">Click here for the web link</a> -
            </p>
        
            <h6 class="pink-text">
                DESCRIPTION
            </h6>
        
            <ul style="font-size: large;">
                <li>Thrust Area : Multidisciplines in Sanskrit and Education</li>
                <li>Frequency of publishing : Half Yearly.</li>
                <li>Mode of publishing : both Off-line and online.</li>
                <li>Language of publication : English and Sanskrit languages.</li>
                <li>Plagirism : Zero Level Plagiarism Tolerance.</li>
            </ul>
        
          </div>
      </div>
    


   


  </div>
</div>

<!-- <div class="section">
<div class="container2">
    <div class="row" >
<div class="col l6 white lighten-5" >
   <div class="section teal darken-1 white-text center"> 
       <span class="center" style="padding: 20px; font-size: large;"> <b> THRUST AREA OF RESEARCH IN EDUCATION  </b> </span>
    </div>

<p style="text-align: justify;" class="teal-text"> 
    The prime aims to provide a forum for all educators, educational policy-makers and planners to exchange invaluable ideas and resources encompasses variety of topics in Education including but are not limited to -
</p>

                    <div class="row">
                        <div class="col s12 l4" *ngFor="let item of list1">
                            <div class="card-panel topic z-depth-2 hoverable" >
                                <span>{{item.topic}}</span>
                            </div>
                        </div>
                    </div>
                
</div>

<div class="col l6 white lighten-5">
    <div class="section cyan darken-1 white-text center"> 
        <span class="center" style="padding: 20px; font-size: large;"> <b> THRUST AREA OF RESEARCH IN SANSKRIT  </b> </span>
     </div>


<p style="text-align:center;" class="cyan-text"> 
    This Journal considers review and research articles related to  -
</p>

            <div class="row">
                <div class="col s12 l6">
                    <ul class="collection black-text">
                        <li class="collection-item z-depth-1 indigo-text">  Vedas, Vedangas, Upanishads and Agamic Systems  </li>
                        <li class="collection-item z-depth-1 indigo-text">  Computation Linguistics</li>
                        <li class="collection-item z-depth-1 indigo-text">  Contemporary Sanskrit Writings</li>
                        <li class="collection-item z-depth-1 indigo-text">  Culture and Tradition</li>
                        <li class="collection-item z-depth-1 indigo-text">  Darsana, Puranas, Grammar, Sahitya</li>
                        <li class="collection-item z-depth-1 indigo-text">  Indian Aesthetics and Poetics</li>
                        <li class="collection-item z-depth-1 indigo-text">  Pali and Prakrit Studies</li>
                        <li class="collection-item z-depth-1 indigo-text">  Pauranic Literature</li>
                        <li class="collection-item z-depth-1 indigo-text">  Religion, History</li>
                        <li class="collection-item z-depth-1 indigo-text">  Sanskrit Studies</li>
                        <li class="collection-item z-depth-1 indigo-text">  Sanskrit Grammar and Linguistics</li>
                        <li class="collection-item z-depth-1 indigo-text">   Manuscriptology and Palaeography</li>

                        
                    </ul>
                </div>
                <div class="col s12 l6">
                    <ul class="collection black-text">
                        <li class="collection-item z-depth-1 indigo-text">  Sanskrit Lexicography</li>
                        <li class="collection-item z-depth-1 indigo-text">  Sanskrit Literature</li>
                        <li class="collection-item z-depth-1 indigo-text">   Scientific Traditions in Sanskrit</li>
                        <li class="collection-item z-depth-1 indigo-text">   Veda and Vedic Studies</li>
                        <li class="collection-item z-depth-1 indigo-text">   Buddhist literature</li>
                        <li class="collection-item z-depth-1 indigo-text">   Indian and Western Logical Systems</li>
                        <li class="collection-item z-depth-1 indigo-text">   Indian Discourse Analysis</li>
                        <li class="collection-item z-depth-1 indigo-text">   Indian Philosophy</li>
                        <li class="collection-item z-depth-1 indigo-text">   Indian Socio-Political Thought</li>
                        <li class="collection-item z-depth-1 indigo-text">   Indological studies</li>
                        <li class="collection-item z-depth-1 indigo-text">   Jainism and Buddhism</li>
                        <li class="collection-item z-depth-1 indigo-text">   Hindu astrology (Jyoti?sastra)</li>
                        <li class="collection-item z-depth-1 indigo-text">   Research Methodology</li>

                       
                    </ul>
                </div>
                
            </div>
         


</div>
</div>
</div>
</div> -->