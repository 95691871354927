import { Component, OnInit } from '@angular/core';
declare var $:any

@Component({
  selector: 'app-noticeboard',
  templateUrl: './noticeboard.component.html',
  styleUrls: ['./noticeboard.component.css']
})
export class NoticeboardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

  $(document).ready(function(){
    $('.tabs').tabs({
      swipeable:true
    });
  });
        
  }

}
