import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http'; 
declare var $:any 

@Component({
  selector: 'app-inspiration',
  templateUrl: './inspiration.component.html',
  styleUrls: ['./inspiration.component.css']
})
export class InspirationComponent implements OnInit {

  constructor(private http: HttpClient) { }

  details : any = []

  ngOnInit(): void {

    this.http.get('assets/allmembers/inspiration.json').subscribe( data => {
        this.details = data 
    })

    $(document).ready(function(){
      $('.slider').slider();
    });

  }

//   images = [
//     {path: 'assets/images/rabindranat tagore.jpg'},
//     {path: 'assets/images/rabindranat tagore.jpg'}
// ]

}
