

      
  <div class="carousel carousel-slider center hide-on-med-and-down">
    <div class="carousel-item" href="#one!">
          <img src="assets/images/b3.png"> 
    </div>    
  </div>


  <!-- <div class="carousel carousel-slider  ">
    <div class="carousel-item" href="#one!"> -->
          <img src="assets/images/b3.png" class="img-responsive hide-on-large-only" width="100%" height="260"> 
    <!-- </div>    
  </div> -->

  <!-- <carousel
  [images]="images"
  [objectFit]="'cover'"
  [cellWidth]="'100%'"
  [arrowsOutside]="true"
  [autoplay]="false"
  [dots]="false"
  [loop]="false"
  [height]="'700'">
</carousel> -->


  <!-- <div class="section banner center"> -->
    <!-- <div class="row"> -->
      <!-- <div class="col l4"> -->
        <!-- <img src="assets/images/logo.jpg"  height="300"> -->
      <!-- </div> -->
      <!-- <div class="col s12"> -->
        <!-- <h3> National Sanskrit University </h3> -->
        <!-- <h6>A Central University established by an Act of Parliament </h6> -->
        <!-- <p>(Formarly Rashtriya Sanskrit Vidyapeetha, Deemed to be University)</p> -->
      <!-- </div> -->
    <!-- </div> -->
      
  <!-- </div> -->


<!--   
  <div class="slider">
    <ul class="slides">
      <li>
        <img src="assets/images/adminbuil2.jpeg" class="responsive-img22" height="100%" width="100%"> 
      </li>
     
    </ul>
  </div> -->
  <!-- <div class="section banner"> </div> -->

    <table>
      <tr>
        <!-- <td class="red center" style="width: 150px;">  
          <span class="center white-text" style="font-size: large;"> Latest News </span>
        </td> -->
        <td class="indigo darken-4 white-text">
          <marquee direction="left"  onmouseover="stop()" onmouseout="start()"> 
            <span class="white-text" style="font-size: large;">  <i class="material-icons left blink_me small white-text">fiber_new</i>

              <!-- <img src="assets/images/new.gif" width="40" height="40" class="left"> -->
               Research papers are invited to publish in ISSUE-II from July-December-2024. </span>
          </marquee>
        </td>
      </tr>
    </table> 
    
