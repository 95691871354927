<div class="seciton bg center" style="padding: 50px;">
    <span class="center white-text" style="font-size: xx-large"> INSPIRATION </span>
</div>

<div class="section grey lighten-3">

    <div class="container" style="position:relative;top:-50px;">
    <div class="row">
        <div class="col s12 l4" *ngFor="let item of details; let i = index">
          <div class="card hoverable z-depth-4" style="height: 460px;">
            <div class="card-image">
              <img src="{{item.imageloc}}" height="290" class="img-responsive animate__animated animate__pulse">  
              
            </div>
            <div class="card-content center animate__animated animate__fadeInUp animate__delay-0.5s">
                <span class="purple-text"> <b> {{item.adjective}} </b></span><br>
                <span class="indigo-text"><b> {{item.name }}</b></span> <br>
                 <span class="black-text"> <b>{{item.designation}}</b>,</span><br>
                 <span class="black-text"> {{item.add}}</span><br>
                
            </div>
          </div>
        
        </div>
      </div>
            
   


    

</div>