import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aboutjournal',
  templateUrl: './aboutjournal.component.html',
  styleUrls: ['./aboutjournal.component.css']
})
export class AboutjournalComponent implements OnInit {

 list1 = [
  {"topic":"Teaching and learning"},
  {"topic":"Curriculum"},
  {"topic":"Pedagogy"},
  {"topic":"Reading comprehension"},
  {"topic":"Philosophies of education"},
  {"topic":"Vocational education"},
  {"topic":"Higher education"},
  {"topic":"Educational psychology"},
  {"topic":" Bilingualism"},
  {"topic":" Special education"},
  {"topic":" Institutional management and performance"},
  {"topic":" Child development"},
  {"topic":" Multicultural studies"},
  {"topic":" Social theory"},
  {"topic":" Educational approaches"}
 ]

  constructor() { }

  ngOnInit(): void {
  }

}
