import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-convocation',
  templateUrl: './convocation.component.html',
  styleUrls: ['./convocation.component.css']
})
export class ConvocationComponent implements OnInit {

  constructor(private http:HttpClient) { }

  forms : any = []

  ngOnInit(): void {

    this.http.get('assets/convocation/convocation.json').subscribe( data => {
        this.forms = data
    })
  }
}
