<div class="seciton bg center" style="padding: 30px;">
    <p class="center white-text" style="font-size: xx-large"> Gallary </p>
</div>

<div class="section grey lighten-4" >
   
    <div class="container" style="position:relative;top:-50px;">
        
      <img src="assets/gallary/release-image1.jpeg" class="responsive-img z-depth-3" width="100%" height="500" /><br>
      <p style="font-size: large; text-align: justify;"> The Shodha Samiksha, (Volume-X ISSUE- I) has been released by Dr.Pratap Chandra Sarangi, Hon'ble Minister of State(Govt of India) for Animal Husbandry, Dairying and Fisheries and MSME along with Smt. Archana Nayak, Ex.MP; Professor Dilip Nayak of EFLU; Professor Satyanarayan Acharya; Professor Radhagovinda Tripathy and Dr. Gyanaranjan Panda of National Sanskrit University, Tirupati on 30.01.2021 at Odisha State Guest House, Bhubaneswar, Odisha.</p>

    </div> 
</div>