import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-downloadforms',
  templateUrl: './downloadforms.component.html',
  styleUrls: ['./downloadforms.component.css']
})
export class DownloadformsComponent implements OnInit {

  constructor(private http:HttpClient) { }

  forms : any = []

  ngOnInit(): void {

    this.http.get('assets/forms/forms.json').subscribe( data => {
        this.forms = data
    })
  }

}
