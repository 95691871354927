
<!-- <ul class="collection with-header">
                        <li class="collection-header indigo white-text"> <h6>Latest News</h6> </li>
                        <li class="collection-item"> -->
                               <!-- <marquee class="card-panel" direction="up" height="500" onmouseover="stop()" onmouseout="start()">  -->
<div class="section">
  <div class="container2">
    <!-- <h6 class="white-text"> Download Forms</h6>  -->
      <div class="collection">
          <a class="collection-item" *ngFor="let item of latestNews">
               <span>  <i class="material-icons left pink-text">link</i>  <span class="black-text"> {{item.title}}</span></span> <br>
                <!-- <span>{{item.description}}</span> <br> -->
                <span class="grey-text">   <i class="material-icons left">update</i> Posted On: {{item.date}}</span>                       
          </a>                         
        </div>
    </div>
</div>         
            
            
                        <!-- </marquee> -->
                            <!-- </li>
                        
                    </ul>
         -->