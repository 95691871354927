<div class="section">
    <div class="container22">
        <!-- <h5 class="center card-panel"> Notice Board</h5> -->
        <ul id="tabs-swipe-demo" class="tabs z-depth-5 hoverable">
            <li class="tab col s2"><a class="active pink-text" href="#test-swipe-1"><b>Latest News</b></a></li>
            <li class="tab col s2"><a href="#test-swipe-2" class="pink-text"><b>notifications</b></a></li>
            <li class="tab col s2"><a href="#test-swipe-3" class="pink-text"><b>Resutls</b></a></li>
            <li class="tab col s3"><a href="#test-swipe-4" class="pink-text"><b>Download Forms</b></a></li>
            <li class="tab col s3"><a href="#test-swipe-6" class="pink-text"><b>Convocations</b></a></li>


        </ul>
        <div id="test-swipe-1" class="col s12 grey lighten-4 scrollable" style="overflow-y: auto; height: 700px;">
            <app-latestnews></app-latestnews>
        </div>
        <div id="test-swipe-2" class="col s12 grey lighten-4" style="overflow: auto; height: 700px;">  
            <app-latestnews></app-latestnews> 
        </div>
        <div id="test-swipe-3" class="col s12 grey lighten-4" style="overflow: auto; height: 700px;">
            <app-latestnews></app-latestnews>
        </div>
        <div id="test-swipe-4" class="col s12 grey lighten-4" style="overflow: auto; height: 700px;">
            <app-downloadforms></app-downloadforms>
        </div>
        <!-- <div id="test-swipe-5" class="col s12 grey lighten-4" style="overflow: auto; height: 600px;">
            <app-downloadforms></app-downloadforms>
        </div> -->
        <div id="test-swipe-6" class="col s12 grey lighten-4" style="overflow: auto; height: 700px;">
            <app-convocation></app-convocation>
        </div>

    </div>
</div>