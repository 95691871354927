<div class="section">
    <ul class="collection">

        <li *ngFor="let item of headerItems" class="collection-item mysidenav"> 
            <a href="#!" routerLink="/{{item.link}}" *ngIf="item.subheader=='no'">
                <i class="material-icons left tiny pink-text">{{item.icon}}</i> {{item.menuname }} 
            </a> 
            <a class="subheader" *ngIf="item.subheader=='yes'" class="pink-text"> {{item.menuname }} </a> 
          </li>
    </ul>
</div>