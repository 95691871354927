<!-- <div class="seciton bg center" style="padding: 50px;">
    <span class="center white-text" style="font-size: xx-large"> JOURNAL CONTENT </span>
</div>
<div class="section grey lighten-3">

    <div class="container222" style="position:relative;top:-50px;">
        <div class="row">
            <div class="col s12 l4" >
                <div class="collection" style="overflow: auto; height: 700px;">
                    <a class="collection-item indigo white-text"> <h6>Click below to see details</h6> </a>
                    <a href="#!" class="collection-item" *ngFor="let list of journals" (click)="showContent(list)">   <i class="material-icons left">book</i>

                        <span class="">VOL-{{list.volume_no}}</span> &nbsp;
                        <span class="blue-text">ISSUE-{{list.issue_no}}</span> &nbsp;
                        <span class="red-text">{{list.month_from}}-{{list.month_to}}</span> &nbsp;
                        <span class="green white-text badge">{{list.year}}</span>
                    </a>
                  </div>
            </div>
            <div class="col s12 l8" style="height: 800px;">
                <div class="card-panel center" *ngIf="shwCover">
                    <div class="row">
                        <div class="col s12 l6">
                            <img src="assets/images/cover-1.jpg" width="350" height="550" class="hoverable img-responsive">
                        </div>
                        <div class="col s12 l6">
                            <img src="assets/images/cover-2.jpg" width="350" height="550" class="hoverable img-responsive">
                        </div>
                    </div>
                    
               
                </div>
                <div class="card-panel" *ngIf="shwVolDet">
                    <h6 class="purple-text"> VOLUME DETAILS</h6><hr>
                    <div class="row">
                        <div class="col s12 l7">
                            <table>
                                
                                    <tr><td class=" red-text"> <i class="material-icons left">event_note</i> VOLUME NO.  </td><td> {{vol}}</td></tr>
                                    <tr><td class=" red-text"> <i class="material-icons left">event_note</i> ISSUE NO.</td><td> {{issue}}</td></tr>
                                    <tr><td class=" red-text"> <i class="material-icons left">event_note</i> PUBLISHED </td><td> {{monthfrm}}-{{monthto}}</td></tr>
                                    <tr><td class=" red-text"> <i class="material-icons left">event_note</i> YEAR</td><td> {{year}}</td></tr>
                                
                            </table>
                            <br>
                            <a href="{{content_path}}" target="_blank" class="btn-flat green white-text center"> Download Content </a> &nbsp;
                            <a href="{{article_path}}" target="_blank" class="btn-flat blue white-text center" (click)="checkArticle(article_path)">  Download Article </a>
                        </div>
                        <div class="col s12 l5" style="overflow: auto; height: 600px;">
                            <pdf-viewer [src]="pdfSource"
                                        [original-size]="false"
                                        [zoom]="1"
                                        [autoresize]="true"
                                        [show-borders]="true"
                             ></pdf-viewer>
                                
                        </div>
                    </div>
                </div>
               
               
            </div>
        </div>
            
    </div>
            
</div>
 -->

<!-- <div class="section grey lighten-3"> -->

    <!-- <div class="container222"> -->
        <div class="row">
            <div class="col s12" >
                <ul class="collection">
                    <li href="#!" class="collection-item z-depth-3" *ngFor="let list of journals" (click)="showContent(list)">   
                        <div class="row">
                            <div class="col s3 l2">
                                <img src="assets/images/cover-1.jpg" width="50" height="50" class=" left hoverable img-responsive">
                            </div>
                            <div class="col s7 l8">
                                
                                <span class="">VOL-{{list.volume_no}}</span>&nbsp;
                                <span class="">ISSUE-{{list.issue_no}}</span>&nbsp;
                                <span class="">{{list.month_from}}-{{list.month_to}}</span>&nbsp;
                            </div>
                            <div class="col s2 l2 pink">
                                <div class="z-depth-1"><span class="white-text">{{list.year}}</span></div>
                                
                            </div>
                        </div>
                        <!-- <div class="red" style="height: 1px;margin-bottom: 3px;">&nbsp;</div> -->
                        <hr>
                                <a href="{{list.content_path}}" target="_blank" class="btn-flat green white-text center btn-small"> <i class="material-icons left">download</i>Content </a> &nbsp;
                            
                           
                                <a href="{{list.article_path}}" *ngIf="list.article_path == 'null'" target="_blank" class="btn-flat white-text center btn-small disable-link" (click)="checkArticle(list.article_path)" style="background: skyblue;">  <i class="material-icons left">download</i> Article </a>

                                <a href="{{list.article_path}}" *ngIf="list.article_path != 'null'" target="_blank" class="btn-flat blue white-text center btn-small" (click)="checkArticle(list.article_path)">  <i class="material-icons left">download</i> Article </a>

                                <!-- <a class="btn-flat blue white-text center btn-small modal-trigger" href="#modal1" (click)="checkArticle(list.article_path)">  <i class="material-icons left">download</i> Article </a> -->


                    </li>
                        
                </ul>
            </div>
            
        </div>
            
    <!-- </div> -->
            
<!-- </div> -->

<!-- 
<a class="waves-effect waves-light btn modal-trigger" href="#modal1">Modal</a>

<div id="modal1" class="modal modal-fixed-footer">
  <div class="modal-content">
    <iframe src="{{PDF_path}}" width="800" height="600"> </iframe>
  </div>
  <div class="modal-footer">
    <a href="#!" class="modal-close waves-effect waves-green btn-flat">Agree</a>
  </div>
</div> -->