import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-advisoryboard',
  templateUrl: './advisoryboard.component.html',
  styleUrls: ['./advisoryboard.component.css']
})
export class AdvisoryboardComponent implements OnInit {

  constructor(private http:HttpClient) { }

  boards:any = []
  ngOnInit(): void {
    this.http.get('assets/advisoryboard/advisoryboard.json').subscribe( data => {
      this.boards = data
    })
  }

}
