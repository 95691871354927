
  <div class="section multicolor22 hide-on-med-and-down indigo darken-4" style="padding: 4px;"> 
    <span class="white-text" style="margin-left: 20px;"> शोधसमीक्षा (SHODHA SAMIKSHA)</span>
    
    <a href="#!" routerLink="contactus" class="right white-text" style="margin-right: 30px;"> <i class="material-icons left">contact_phone</i>Contact Us</a> 
    <a href="#!" routerLink="/" class="right white-text" style="margin-right: 20px;"> <i class="material-icons left">home</i>Home</a>&nbsp; &nbsp; &nbsp;
    
  </div>
                <div class="row hide-on-med-and-down"><br>
                    <div class="col s3 l1 center">
                        <img src="assets/images/logo.jpg" class="responsive-img">
                    </div>
                    <div class="col s9 l11 left-align">
                            <span style="font-size: x-large" class="hide-on-med-and-down pink-text darken-4"> शोधसमीक्षा (SHODHA SAMIKSHA) <br> </span>
                            <span style="font-size: 16px;" class="hide-on-large-only pink-text darken-4 center"> शोधसमीक्षा (SHODHA SAMIKSHA)<br></span>
                            <span style="font-size: normal; text-align: center;" class="black-text center"> (A National Peer Reviewed Referred and UGC Care-listed Journal of Research in Education and Sanskrit)</span><br>
                            
                    </div>
                   
                </div>
    <!-- </div> -->
    
<!-- <div class="navbar-fixed22 "> -->
    <nav class="indigo darken-4 header22 hoverable z-depth-4">
        <div class="nav-wrapper">          
          <!-- <a href="#" class="brand-logo hide-on-med-and-down" style="font-size: xx-large;">&nbsp; शोधसमीक्षा</a> -->
          <a href="#" class="hide-on-large-only center" style="font-size: large;">&nbsp; शोधसमीक्षा (SHODHA SAMIKSHA) </a>
          <a href="#" data-target="slide-out" class="sidenav-trigger"><i class="material-icons">menu</i></a>
          <ul id="nav-mobile" class=" center hide-on-med-and-down">
            <!-- <li><a href="/"> <i class="material-icons left">home</i>Home</a></li> -->
            
            <li><a class="dropdown-trigger" href="#!" data-target="aboutus"><i class="material-icons left">web</i> About Us<i class="material-icons right">arrow_drop_down</i></a></li>
          
            <li><a class="dropdown-trigger" href="#!" data-target="board"><i class="material-icons left">people</i> Board<i class="material-icons right">arrow_drop_down</i></a></li>
            <!-- <li routerLink="editorial"><a href="#!" > <i class="material-icons left">insert_chart</i>Editorial Board</a></li> -->

            <li ><a href="#!" routerLink="members"> <i class="material-icons left">people</i>Members</a></li>
            <li><a class="dropdown-trigger" href="#!" data-target="guideline"><i class="material-icons left">collections</i> Guidelines & Policy<i class="material-icons right">arrow_drop_down</i></a></li>
            <li ><a href="#!" routerLink="peerreview"> <i class="material-icons left">collections</i>Peer Reviewers</a></li>
            <li ><a href="#!" routerLink="declaration"> <i class="material-icons left">collections</i>Declaration</a></li>
            <!-- <li routerLink="content"><a href="#!" > <i class="material-icons left">collections</i>Journal Content</a></li> -->
            <!-- <li routerLink="guidelines"><a href="#!" > <i class="material-icons left">collections</i>Guidelines</a></li>
            <li routerLink="declaration"><a href="#!" > <i class="material-icons left">collections</i>Declaration</a></li>
            <li routerLink="peerpolicy"><a href="#!" > <i class="material-icons left">collections</i>Peer Review Policy</a></li> -->
            <li><a href="#!" routerLink="/subscription" > <i class="material-icons left">collections</i>Subscription Form</a></li>
            <li ><a href="#!" routerLink="gallary"> <i class="material-icons left">image</i>Gallary</a></li>          
          </ul>
        </div>
      </nav>

      <ul id="aboutus" class="dropdown-content indigo darken-4">
        <li><a href="#!" routerLink="about" class="white-text"> About Journal</a></li>
        <li><a href="#!"  routerLink="inspiration" class="white-text"> Inspiration</a></li>
      </ul>

      <ul id="board" class="dropdown-content indigo darken-4">
        <li  ><a href="#!" routerLink="editorial" class="white-text">Editorial Board</a></li>
        <li ><a href="#!" routerLink="advisory" class="white-text">Advisory Board</a></li>
      </ul>

      <ul id="guideline" class="dropdown-content indigo darken-4">
        <li ><a href="#!" routerLink="guidelines" class="white-text"> <i class="material-icons left">collections</i>Guidelines</a></li>
       
            <li ><a href="#!" routerLink="peerpolicy" class="white-text"> <i class="material-icons left">collections</i>Peer Review Policy</a></li>
      </ul>

<!-- </div> -->
      <ul id="slide-out" style="width: 300px;" class="sidenav grey lighten-5"> 
        <li class="sidenav-close white">
          <div class="row">
            <div class="col s12 center">
              <img src="assets/images/logo.jpg" style="margin-top: 10px;" height="100">
            </div>
            <div class="col s12 center">
               <span class="purple-text" style="font-size: large;">   शोधसमीक्षा (SHODHA SAMIKSHA) </span>
               <span> shodhasamiksha74@gmail.com </span>
              </div>
          </div>
        </li>

        <li  class="sidenav-close"><a href="/"> <i class="material-icons left">home</i>Home</a></li>
        <li  class="sidenav-close"  routerLink="/about"><a href="#!"> <i class="material-icons left">web</i>About Journal</a></li>
        <li  class="sidenav-close"  routerLink="/inspiration"><a href="#!" > <i class="material-icons left">insert_chart</i>Inspiration</a></li>
        <li  class="sidenav-close"  routerLink="/editorial"><a href="#!" > <i class="material-icons left">people</i>Editorial Board</a></li>

        <li class="sidenav-close"  routerLink="advisory"><a href="#!"><i class="material-icons left">people</i>Advisory Board</a></li>

        <li  class="sidenav-close"  routerLink="/members"><a href="#!" > <i class="material-icons left">people</i>Members</a></li>
        <li  class="sidenav-close"  routerLink="/content"><a href="#!" > <i class="material-icons left">collections</i>Journal Content</a></li>
        <li class="sidenav-close" routerLink="guidelines"><a href="#!" > <i class="material-icons left">collections</i>Guidelines</a></li>
        <li class="sidenav-close" routerLink="declaration"><a href="#!" > <i class="material-icons left">collections</i>Declaration</a></li>
        <li class="sidenav-close" routerLink="peerpolicy"><a href="#!" > <i class="material-icons left">collections</i>Peer Review Policy</a></li>
        <li class="sidenav-close" routerLink="peerreview"><a href="#!" > <i class="material-icons left">collections</i>Peer Reviewers</a></li>
        <li class="sidenav-close" routerLink="gallary"><a href="#!"> <i class="material-icons left">image</i>Gallary</a></li>          

        <li  class="sidenav-close" routerLink="contactus"><a href="#!"> <i class="material-icons left">contact_phone</i>Contact Us</a></li> 
      
      </ul>
            
      
<!-- 
  <header>
    <nav class="top-nav">
      <div class="nav-wrapper">
          <ul class="right hide-on-med-and-down">
              <li><a href="#">Log In</a></li>
              <li><a href="#">Sign Up</a></li>
          </ul>
      </div>
    </nav>
    <ul style="width:120px; margin-top:65px;" class="sidenav sidenav-fixed">
      <li><a href="#">Icon 1</a></li>
      <li><a href="#">Icon 2</a></li>
    </ul>      
</header> -->