<div class="seciton bg center" style="padding: 20px;">
    <p class="center white-text" style="font-size: xx-large"> Declaration </p>
</div>


<div class="section grey lighten-3">
<div class="container">
    
        <div class="card-panel">
            <div class="row" *ngFor="let item of declaration">
                <div class="col s12 l4">
                    <p class="indigo-text">{{item.id}}. <b>{{item.title}}</b></p>
                </div>
                <div class="col s12 l8 left">
                    <p>{{item.content}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col s12 l12">
                    <p style="text-align: justify; margin-bottom: 20px;">
                        I, Purnima Panda declare that I am the Printer / Publisher of the Journal entitled <span class="pink-text">"SHODHA SAMIKSHA"</span> A National Peer Reviewed Referred Journal of Research in Education & Sanskrit and the particulars in respect of the said Journal given above are true to the best of my knowledge and belief.
                    </p> 
                </div>
                
                <div class="col s12 l10">
                    <p> Sainkul, Keonjhar, Odisha</p>
                </div>
                <div class="col s12 l2 right black-text">
                    <p> Purnima Panda <br> Publisher</p>
                </div>
            </div>
            <!-- <p>{{item.title}}</p> -->
        </div>

</div>
</div>