<div class="section">
 
    <form *ngFor="let details of migrationForms" class="card-panel topborder" #migration="ngForm" (ngSubmit)="getPdf(migration)" > 
        
        <div class="" *ngFor="let title of details.formtitle">
            <h6 class="center red-text">NATIONAL SANSKRIT UNIVERSITY:TIRUPATI</h6>
            <h5 class="center pink-text"> {{title.title }} </h5><br>
            <input type="hidden"  name="formTitle"  [ngModel]="title.title">
        </div>

        <div class="row">
            <div class="col s12 l6" *ngFor="let item of details.name">
                <div class="input-field">
                    <i class="material-icons prefix">{{item.icon}}</i>
                    <input id="{{item.id}}" name="{{item.id}}" type="{{item.type}}" class="validate" ngModel required>
                    <label for="{{item.id}}">{{item.lable}}</label>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col  s12  l6" *ngFor="let item of details.father">
                <div class="input-field" >
                    <i class="material-icons prefix">{{item.icon}}</i>
                    <input id="{{item.id}}" name="{{item.id}}" type="{{item.type}}" class="validate" ngModel>
                    <label for="{{item.id}}">{{item.lable}}</label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col  s12 l6" *ngFor="let item of details.mother">
                <div class="input-field">
                    <i class="material-icons prefix">{{item.icon}}</i>
                    <input id="{{item.id}}" name="{{item.id}}" type="{{item.type}}" class="validate" ngModel>
                    <label for="{{item.id}}">{{item.lable}}</label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col s12  l3" *ngFor="let item of details.address">
                <div class="input-field">
                    <i class="material-icons prefix">{{item.icon}}</i>
                    <input id="{{item.id}}" name="{{item.id}}" type="{{item.type}}" class="validate" ngModel>
                    <label for="{{item.id}}">{{item.lable}}</label>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col  s12 l6" *ngFor="let item of details.email">
                <div class="input-field">
                    <i class="material-icons prefix">{{item.icon}}</i>
                    <input id="{{item.id}}" name="{{item.id}}" type="{{item.type}}" class="validate" ngModel>
                    <label for="{{item.id}}">{{item.lable}}</label>
                </div>
            </div>
        </div>
        
        <div class="row">
            <div class="col  s12 l6" *ngFor="let item of details.admin_nad_no">
                <div class="input-field">
                    <i class="material-icons prefix">{{item.icon}}</i>
                    <input id="{{item.id}}" name="{{item.id}}" type="{{item.type}}" class="validate" ngModel>
                    <label for="{{item.id}}">{{item.lable}}</label>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col s12" *ngFor="let item of details.exam_details">
                <div class="input-field">
                    <i class="material-icons prefix">{{item.icon}}</i>
                    <input id="{{item.id}}" name="{{item.id}}" type="{{item.type}}" class="validate" ngModel>
                    <label for="{{item.id}}">{{item.lable}}</label>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col s12" *ngFor="let item of details.admin_details">
                <div class="input-field">
                    <i class="material-icons prefix">{{item.icon}}</i>
                    <input id="{{item.id}}" name="{{item.id}}" type="{{item.type}}" class="validate" ngModel>
                    <label for="{{item.id}}">{{item.lable}}</label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col s12 l6" *ngFor="let item of details.challen">
                <div class="input-field">
                    <i class="material-icons prefix">{{item.icon}}</i>
                    <input id="{{item.id}}" name="{{item.id}}" type="{{item.type}}" class="validate" ngModel>
                    <label for="{{item.id}}">{{item.lable}}</label>
                </div>
            </div>
        </div>
        <div class="center-align">
        <input class="btn-flat btn-small green white-text" value="PREVIEW/PRINT FORM"  type="submit" /> 
        <!-- <button class="btn-flat btn-small pink white-text" (click)="captureScreen()"> SUBMIT APPLICATION </button> -->
        </div>
    </form>

</div>

