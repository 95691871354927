import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subscriptionform',
  templateUrl: './subscriptionform.component.html',
  styleUrls: ['./subscriptionform.component.css']
})
export class SubscriptionformComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
