<div class="seciton bg center" style="padding: 30px;">
    <p class="center white-text" style="font-size: xx-large"> Editorial Board </p>
</div>

<div class="section grey lighten-4">

    <div class="container" style="position:relative;top:-50px;">

        <div class="row">
            <div class="col s12 card-panel">
                <div class="row">
                    <div class="col s12 l4">
                        <ul *ngFor="let alldet of editors" class="collection with-header">
                            <li class="collection-header">
                                <h5 class="purple-text center"> Editor</h5>
                            </li> 
                            <li  class="collection-item center"  *ngFor="let member of alldet.editor">
                                <div class="row">
                                    <div class="col l12">
                                        <img src="{{member.image}}" class="edimg">
                                    </div>
                                    <div class="col s12 center">
                                        <p class="pink-text" style="font-size: large;"><b>{{member.name }}</b></p>
                                        <p *ngIf="member.designation != ''"> {{member.designation}}, </p>
                                        <p> {{member.department}},</p>
                                        <p> {{member.institute}},</p>
                                        <p> {{member.state}}</p>
                                    </div>
                                </div>
                                  
                               
                            </li>
                            <!-- <li class="collection-item">
                                <h3 class="purple-text"> &nbsp;</h3>
                            </li>  -->
                        </ul>
                    </div>
                    <div class="col s12 l8">
                        <!-- <div > -->
                            <!-- <h5 class="purple-text"> Assocaite Editors</h5><hr> -->
                            <ul class="collection with-header" *ngFor="let alldet of editors">
                                <li class="collection-header">
                                    <h5 class="purple-text"> Assocaite Editors </h5>
                                </li> 
                                <li class="collection-item avatar" *ngFor="let member of alldet.associate_editor">

                                    <div class="row">
                                        <div class="col s3">
                                            <img src="{{member.image}}" alt="" class="right myimg materialboxed">
                                        </div>
                                        <div class="col s9">
                                            <p class="pink-text"><b>{{member.name }}</b></p>
                                            <p *ngIf="member.designation != ''"> {{member.designation}}, </p>
                                            <p *ngIf="member.department != ''"> {{member.department}},</p>
                                            <p *ngIf="member.institute != ''"> {{member.institute}},</p>
                                            <p> {{member.state}}.</p> 
                                        </div>
                                    </div>
                                    
                                  </li>
                            </ul>
                        <!-- </div> -->
                    </div>
                </div>
               
                
            </div>
            <div class="col s12 card-panel">
                <!-- <div *ngFor="let alldet of editors" class=""> -->
                  
                    <ul class="collection with-header" *ngFor="let alldet of editors" >
                        <li class="collection-header ">
                            <h5 class="purple-text"> Editorial Board</h5>
                        </li> 
                        <li *ngFor="let member of alldet.editorial_board" class="collection-item avatar" >
                            <img src="{{member.image}}" alt="" class="circle">
                            <p class="pink-text"><b>{{member.name }}</b></p>
                            <p *ngIf="member.designation != ''"> {{member.designation}},</p>
                            <p *ngIf="member.department != ''"> {{member.department}},</p>
                            <p> {{member.institute}},</p>
                            <p> {{member.state}}.</p>
                        </li>
                    </ul>
                <!-- </div> -->
            </div>
        </div>
        

        

        

    </div>
</div>