
<header>
        <app-header></app-header>

</header>
 
<main>
        <!-- <div class="row">
                <div class="col s12 l3 hide-on-med-and-down">
                        <app-leftpanel></app-leftpanel>                    
                </div>
                <div class="col s12 l9">
                        <router-outlet></router-outlet>
                </div>
        </div>     -->
        <router-outlet></router-outlet>
        <!-- <app-rulesregulations></app-rulesregulations>   -->
</main>

<footer>
    <app-footer></app-footer>
</footer>
