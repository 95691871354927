import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-topics',
  templateUrl: './topics.component.html',
  styleUrls: ['./topics.component.css']
})
export class TopicsComponent implements OnInit {

  list1 = [
    {"topic":"Teaching and learning"},
    {"topic":"Curriculum"},
    {"topic":"Pedagogy"},
    {"topic":"Reading comprehension"},
    {"topic":"Philosophies of education"},
    {"topic":"Vocational education"},
    {"topic":"Higher education"},
    {"topic":"Educational psychology"},
    {"topic":" Bilingualism"},
    {"topic":" Special education"},
    {"topic":" Institutional management and performance"},
    {"topic":" Child development"},
    {"topic":" Multicultural studies"},
    {"topic":" Social theory"},
    {"topic":" Educational approaches"}
   ]
  
  constructor() { }

  ngOnInit(): void {
  }

}
