import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2'
declare var $:any

@Component({
  selector: 'app-journalcontent',
  templateUrl: './journalcontent.component.html',
  styleUrls: ['./journalcontent.component.css']
})
export class JournalcontentComponent implements OnInit {

  constructor(private http:HttpClient) { }

  journals: any = []

  ngOnInit(): void {

    this.http.get('assets/journals/journal.json').subscribe( joun =>
      {
        this.journals = joun
        console.log(this.journals)
      }
    )

    $(document).ready(function(){
      $('.modal').modal();
    });

  }

  vol : any 
  issue: any 
  monthfrm : any
  monthto: any 
  year: any
  content_path: any 
  article_path : any 
  image_path : any 

  pdfSource : any 
  shwCover = true
  shwVolDet = false

  showContent(details:any){
   
    this.vol = details.volume_no
    this.issue = details.issue_no 
    this.monthfrm = details.month_from
    this.monthto = details.month_to
    this.year = details.year
    this.content_path = details.content_path
    this.article_path = details.article_path
    this.image_path = details.image
    this.pdfSource = this.content_path
    this.shwVolDet = true
    this.shwCover = false
  }

  PDF_path :any 
  shwArticle = true
  checkArticle(art:any){
    this.PDF_path = "http://localhost:4200/"+art
    if(art == 'null'){
      this.shwArticle = false
      // alert("Opps Article Not Found For Current Volume")
      Swal.fire({
        text: "Opps Article Not Found For Current Volume!!",
        icon:"info"
      })
      return false
    }
  }

}
