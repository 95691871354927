import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lifemembers',
  templateUrl: './lifemembers.component.html',
  styleUrls: ['./lifemembers.component.css']
})
export class LifemembersComponent implements OnInit {

  constructor(private http:HttpClient) { }
  memberList : any = []

  ngOnInit(): void {
      this.http.get('assets/members/members.json').subscribe( data => {
        this.memberList = data
      })
  }

}
