<!-- <div class="section black white-text" style="padding:30px;" >
    <marquee><h6> Prak-Sastri Results Click Here </h6></marquee>
</div> -->

<div class="container" style="opacity: 0.1;">
<div class="row" >
    <div class="col s12 l4" style="padding: 20px;">
        <span> Hellow </span>
    </div>
    <div class="col s12 l4" style="padding: 20px;">
        <span> Hellow </span>
    </div>
    <div class="col s12 l4" style="padding: 20px;">
            <span> Hellow </span>
    </div>
</div>
</div>

