<!-- <div class="section bgforms">
    <h4 class="center white-text"> Download Forms</h4>
</div> -->

<div class="section">
<!-- <div class="container2"> -->
    <ul class="collection with-header">
        <li class="collection-header teal"> <h6 class="white-text"> Download Forms</h6> </li>
        <li class="collection-item padding" *ngFor="let item of forms">
            <div class="row">
                <div class="col s12 l8">
                    <i class="material-icons left cyan-text">picture_as_pdf</i>
                    <span class="black-text"> {{item.title }}</span> 
                </div>
                <div class="col s12 l4">
                    <a href="{{item.path}}" target="_blank" class="btn-flat green white-text btn-small"> Download</a> &nbsp;
                    <a *ngIf="item.onlinestatus != 'no'" class="btn-flat purple white-text btn-small" routerLink="/{{item.routlink}}"> Apply Online</a>
                </div>
            </div>
        </li>        
    </ul>

    
<!-- </div> -->
</div>