import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-peerpolicy',
  templateUrl: './peerpolicy.component.html',
  styleUrls: ['./peerpolicy.component.css']
})
export class PeerpolicyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
