import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rulesregulations',
  templateUrl: './rulesregulations.component.html',
  styleUrls: ['./rulesregulations.component.css']
})
export class RulesregulationsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
