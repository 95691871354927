<footer class="page-footer grey darken-3">
        <div class="container">
          <div class="row">
            <div class="col l6 s12">
              <h5 class="white-text">शोधसमीक्षा (SHODHA SAMIKSHA)
               </h5>
              <p class="grey-text text-lighten-4"> (A National Peer Reviewed Referred Journal of Research in Education and Sanskrit)</p>
              <h6 class="white-text">PUBLISHER</h6>
              <p>
                Ratha Seve Pratishthanam<br>
                Sainku, Keonjhar, Odisha,<br>
                India, PIN-758 043</p>
                <p><i class="material-icons left">mail</i> shodhasamiksha74@gmail.com</p>
               
              
            </div>
            <div class="col l4 offset-l2 s12">
                <h6>EDITOR</h6>

                <p>
                  Dr. Sanat Kumar Rath<br>
                  Department of Education,<br> 
                  Visva-Bharati, Shantiniketan<br>
                  West Bengal-35.
                </p>

               <p>   <i class="material-icons left">mail</i>
                 shodhasamiksha74@gmail.com</p>

                <p><i class="material-icons left">contact_phone</i> 09832223973</p>
                <a href="https://www.freecounterstat.com" title="free page counter"><img src="https://counter8.stat.ovh/private/freecounterstat.php?c=uut7hgt2aslz2zc7dkl2ws6wfwhdqsht" border="0" title="free page counter" alt="free page counter"></a>
            </div>
          </div>
        </div>
        <div class="footer-copyright hoverable z-depth-4 grey darken-4">
          <div class="container">
            <div class="row">
              <div class="col s12 l9 center">
                © 2023 | RATHA SEVA PRATISHTHANAM, SAINKUL, KEONJHAR, ODISHA, INDIA PIN - 758 043
              </div>
              <div class="col s12 l3 center">
                <span class="grey-text text-lighten-4" target="_blank">Version 1.2</span>
              </div>
              </div>
            </div>
         
          
        </div>
</footer>