<div class="section">
    <div class="card-panel">
        <h6 class="black-text">About Us</h6> 
        <p class="mytext"> 
                The conduct of examinations and declaration of results is one of the important activities of the University. The Examination Department is backbone of the examination system. The Examination Department of Shivaji University conducts nearly 500 examinations every year in which over 4500 question papers are printed and about 12,00,000 answer books are assessed to examine over 2,00,000 students spread over 34 post-graduate departments of the University and 200 odd affiliated colleges and institutions in Kolhapur, Sangli and Satara districts.
        </p>
        <p class="mytext"> 
            The examination system should be fair, efficient, reliable and transparent. The Department strives hard towards these goals to achieve credibility. A fairly good record of the University in smooth conduct of examinations and timely declaration of results, within 30/45 days as required by the Maharashtra Universities Act, 1994, has been time and again appreciated by the Hon’ble Chancellor. In recent times, the University has taken several initiatives to ensure better standards and improve accuracy, efficiency and transparency in the various steps involved in the conduct and declaration of results of examinations. Steps have also been taken to minimize the errors and mistakes and ensure better accuracy and efficiency during paper setting...
        </p>
    </div>
    
</div>