import { Component, OnInit } from '@angular/core';
declare var $:any 

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})
export class SliderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $(document).ready(function(){
       $('.carousel').carousel(
         {
          fullWidth:true
         }
       );
    });

   
  }
  images = [
    {path: 'assets/images/rabindranat tagore.jpg'},
    {path: 'assets/images/rabindranat tagore.jpg'}
]

}
